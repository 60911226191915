import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { pink, amber } from "@material-ui/core/colors";
import Banner from "./aprovec_ds.png";
import Logo from "./logo-hori.png";
import "./App.css";
import { useState, useEffect } from "react";
import { setKey, get } from "./dsapi.js";

const CustomButton = withStyles({
  root: { borderColor: "#F2B502", color: "#F2B502" },
})(Button);
const DIRECT_EXTERNAL_LINK = 'https://wa.me/+5585992966757?text=Ol%C3%A1!%20Conheci%20a%20Prote%C3%A7%C3%A3o%20360%20Aprovec%20+%20DriveSocial%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.';
function App() {
  const [generalState, setGeneralState] = useState({
    busy: false,
    initialized: false,
    token: "none",
    isAvailable: null,
    externalLink: null,
  });
  useEffect(() => {
    if (!generalState.initialized && generalState.token === "none") {
      let token = getTokenFromUri();
      if (typeof token === "undefined" || token == null || token === "") {
        window.location.href = "https://drivesocial.io/";
      }
      setGeneralState({
        busy: false,
        initialized: true,
        token: token,
        isAvailable: null,
      });
    } else {
      if (generalState.isAvailable == null) {
        if (generalState.token === "directOrExternal") {
          setGeneralState({
            ...generalState,
            isAvailable: true,
            externalLink: DIRECT_EXTERNAL_LINK,
          });
        } else {
          setKey(generalState.token);
          get("getMyExternalPartners", "none").then((response) => {
            if (
              response.result === "true" &&
              typeof response.partners !== "undefined"
            ) {
              for (let i = 0; i < Object.keys(response.partners).length; i++) {
                const partnerObj = response.partners[i];
                if (partnerObj.partner_id === "aprovec_ce") {
                  setGeneralState({
                    ...generalState,
                    isAvailable: true,
                    externalLink: partnerObj.partner_external_url,
                  });
                }
              }
            }
          });
        }
      }
    }
  }, [generalState.initialized, generalState.token]);
  function getTokenFromUri() {
    const token = window.location.href.split("?")[1];
    return token;
  }
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ backgroundColor: pink[900], padding: "15px" }}
      >
        <Box display="flex" justifyContent="center">
          <img src={Logo} style={{ width: "200px" }} />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "#1D1E21" }}>
        <Box display="flex" justifyContent="center">
          <img src={Banner} style={{ width: "100%" }} />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ margin: "10px" }}>
        {generalState.isAvailable && (
          <Box display="flex" justifyContent="center">
            <CustomButton
              variant="outlined"
              color="white"
              enabled={generalState.busy ? false : true}
              onClick={() => {
                window.location.href = generalState.externalLink;
              }}
            >
              Cotar Minha Proteção
            </CustomButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} style={{ margin: "10px" }}>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>O que é a Proteção 360?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              Com o DriveSocial, você protege a si mesmo e seus parceiros,
              certo? Agora, além de proteger a si e aos outros, seu carro também
              estará protegido!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>É algum tipo de seguro?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              Trata-se da Evolução do Seguro. Com a Proteção 360, seu veículo
              estará protegido pela APROVEC, a melhor de Proteção Veicular do
              Brasil. São mais 100 mil pessoas que escolheram a Aprovec.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>Quais benefícios para o meu veículo? </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItem>
                <ListItemText>Furto / Roubo</ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>Colisão / Capotamento</ListItemText>
              </ListItem>
              <Divider />

              <ListItem>
                <ListItemText>Incêndio / Fenômenos Naturais</ListItemText>
              </ListItem>
              <Divider />

              <ListItem>
                <ListItemText>
                  Assistência 24 Horas (Guincho, Táxi, Hotel, Chaveiro,
                  Borracheiro, Falta De Combustível E Falha Na Bateria)
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  Proteção Para Terceiros de Até R$ 100 Mil
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  Carro Reserva - Auxílio Vec ( Em caso de Colisão a Aprovec
                  paga R$ 65,00 na diária para o motorista* )
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  Rastreamento Do Veículo E Muito Mais!
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>Existe algum benéfico para minha família ? </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              Sim, a Aprovec não pensa na proteção apenas dos veículos e sim em
              vidas que utilizam o veículo como ferramenta de trabalho ou meio
              de transporte. Todo associado tem direito a incluir 4 dependentes
              (sem vínculo familiar) para usufruir à consultas médicas e
              odontológicas, exames, procedimentos na rede de clínicas
              conveniadas em todo brasil com coparticipação). Também tem direito
              Clube de Vantagens (Descontos exclusivos em diversos
              estabelecimentos comerciais)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>
                Pago alguma coisa a mais para ter esses benefícios na área da
                saúde e residencial?
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              Associado APROVEC, não paga nenhum um centavo a mais para ter
              esses benefícios Auxílio saúde e Assistência Residencial. Já está
              em incluso em para todos os veículos.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>Em quais regiões está disponível?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              Atualmente a PROTEÇÃO 360 está disponível para o{" "}
              <b>Estado do Ceará</b>. Em breve, conforme a demanda, iremos
              estender a <b>Proteção 360 by APROVEC</b> para outros estados e
              cidades.
              <br />
              <br /> Caso você more em outro estado e queira contar com a
              PROTEÇÃO 360, nos envie um email para{" "}
              <b>suporte@drivesocial.io</b> demonstrando seu interesse ;)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#f2f2f2" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body">
              <b>Eu quero! O que eu tenho que fazer?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              É muito simples! Basta clicar no botão{" "}
              <b>'Cotar Minha Proteção'</b> que você será encaminhado para o
              formulário de cotação da <b>APROVEC</b>.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} style={{ margin: "10px" }}>
        {generalState.isAvailable && (
          <Box display="flex" justifyContent="center">
            <CustomButton
              variant="outlined"
              color="white"
              enabled={generalState.busy ? false : true}
              onClick={() => {
                window.location.href = generalState.externalLink;
              }}
            >
              Cotar Minha Proteção
            </CustomButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default App;
