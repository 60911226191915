import axios from "axios";

let sessionKey = "NO_KEY";
const remote = "https://server.drivesocial.io/DSAPI/api19.php";

export function setKey(key) {
  sessionKey = key;
}
export function get(action, argument) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${remote}?action=${action}&argument=${argument}&sessionkey=${sessionKey}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function post(action, argument) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${remote}?action=${action}&argument=${argument}&sessionkey=${sessionKey}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
